import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// material
import { Box, Typography } from '@mui/material';

// style
import useStyles from './style';

export default function TitleSection({ title, highlightTitle, ...props }) {
	const { classes } = useStyles();

	if (!title && !highlightTitle) return null;

	const renderTitle = (title, addSpaceAfter, isHighlightTitle) => {
		if (!title) return null;

		const titleProps = { component: 'span' };

		titleProps.className = `${classes.title} ${
			props.fontSize === titleSizes.medium
				? classes.textMedium
				: props.fontSize === titleSizes.small
				? classes.textSmall
				: classes.textLarge
		}`;

		if (isHighlightTitle) titleProps.color = 'primary';

		return (
			<Typography {...titleProps}>
				{`${addSpaceAfter ? title + ' ' : title}`}
			</Typography>
		);
	};

	let TitleElement;
	if (props.highlightTitleAfter) {
		TitleElement = (
			<Typography component={props.heading ? props.heading : 'h6'}>
				{renderTitle(title, true)}
				{props.isVertical ? <br /> : null}
				{renderTitle(highlightTitle, false, true)}
			</Typography>
		);
	} else {
		TitleElement = (
			<Typography component={props.heading ? props.heading : 'h6'}>
				{renderTitle(highlightTitle, true, true)}
				{props.isVertical ? <br /> : null}
				{renderTitle(title)}
			</Typography>
		);
	}

	return (
		<Box
			className={`${classes.titleWrap} ${
				props.textAlign === 'left'
					? classes.alignLeft
					: props.textAlign === 'right'
					? classes.alignRight
					: classes.alignCenter
			}`}
		>
			{TitleElement}
			{props.subTitle && (
				<Typography variant="body1">{props.subTitle}</Typography>
			)}
		</Box>
	);
}

TitleSection.propTypes = {
	title: PropTypes.string.isRequired,
	highlightTitle: PropTypes.string,
	subTitle: PropTypes.string,
	highlightTitleAfter: PropTypes.bool,
	fontSize: PropTypes.string,
	isVertical: PropTypes.bool,
	textAlign: PropTypes.string,
};

TitleSection.defaultProps = {
	highlightTitleAfter: false,
};
