import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleWrap: {
		textAlign: 'center',
	},
	title: {
		lineHeight: 1.21,
	},
	textLarge: {
		fontSize: '42px',
		fontWeight: 700,
		[theme.breakpoints.down('sm')]: {
			fontSize: '32px',
		},
	},
	textMedium: {
		fontSize: '38px',
		fontWeight: 700,
		[theme.breakpoints.down('sm')]: {
			fontSize: '30px',
		},
	},
	textSmall: {
		fontSize: '36px',
		fontWeight: 700,
		[theme.breakpoints.down('sm')]: {
			fontSize: '28px',
		},
	},
	alignLeft: {
		textAlign: 'left',
	},
	alignRight: {
		textAlign: 'right',
	},
	alignCenter: {
		textAlign: 'center',
	},
}));

export default useStyles;
